// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDyLtbyR_mba71nzbzN2Ok6MBLkMld2WT4",
  authDomain: "mooseman-856b0.firebaseapp.com",
  databaseURL: "https://mooseman-856b0-default-rtdb.firebaseio.com",
  projectId: "mooseman-856b0",
  storageBucket: "mooseman-856b0.appspot.com",
  messagingSenderId: "85915848959",
  appId: "1:85915848959:web:287ec32b5c7b21e8acbcca",
  measurementId: "G-XVPB91FBXN"
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebase);



